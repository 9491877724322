<template>
    <div class="relative bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
            <div
                class="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
            >
                <nav class="space-x-10">
                    <router-link
                        class="text-base font-medium text-gray-500 hover:text-teal-700"
                        :to="{ name: 'Dashboard' }"
                    >
                        Dashboard
                    </router-link>
                </nav>
                <nav class="space-x-10">
                    <router-link
                        class="text-base font-medium text-gray-500 hover:text-teal-700"
                        :to="{ name: 'AddCurrency' }"
                    >
                        New Currency
                    </router-link>
                </nav>
                <div class=" md:flex items-center justify-end md:flex-1 lg:w-0">
                    <router-link
                        class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-teal-700"
                        :to="{ name: 'SignOut' }"
                    >
                        Sign out
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Nav',
}
</script>
